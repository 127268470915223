import React, { useState, useEffect, useContext } from "react";
import "./Header.css";
import axios from "axios";
import Detail from "./Detail";
import { GetDataone } from "./Aminittes";
import { AuthContext } from "../Config/Context/Context";
const geonamesUsername = "sywyj";
const HeaderHello = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  let { filters, setFilters } = useContext(AuthContext);

  // const [filters, setFilters] = useState({
  //   selectedState: "",
  //   selectedCity: "",
  //   baths: "",
  //   cityTown: "",
  //   beds: "",
  //   price: "",
  //   sorts: "",
  //   type: "",
  //   rentPeriod: "",
  //   availability: "",
  // });

  const [filteredData, setFilteredData] = useState([]);
  const [type, settype] = useState([]);
  const toggleDropdownVisibility = () => {
    setDropdownVisible(!dropdownVisible);
  };
  useEffect(() => {
    GetDataone().then((e) => {
      settype(e?.data?.data);
    });
  }, []);

  const dropdownOptions = {
    baths: [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5+", value: "5" },
    ],
    beds: [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5+", value: "5" },
    ],
    price: [
      { label: "GH¢100-GH¢500", value: "100_to_500" },
      { label: "GH¢500-GH¢1000", value: "500_to_1000" },
      { label: "GH¢1000-GH¢1500", value: "1000_to_1500" },
      { label: "GH¢1500-GH¢2000", value: "1500_to_2000" },
      { label: "GH¢2500-GH¢3000", value: "2500_to_3000" },
      { label: "Above-GH¢3000", value: "above_3000" },
    ],
    sorts: [
      { label: "Newest-to-oldest", value: "desc" },
      { label: "Oldest-to-newest", value: "asc" },
      { label: "Price - highest-to-lowest", value: "price_desc" },
      { label: "Price - lowest-to-highest", value: "price_asc" },
    ],
    type: ["Room", "House", "Apartment"],
    rentPeriod: [
      "Daily",
      "Weekly",
      "1-to-6 months",
      "12 months",
      "12-24 months",
      "24-36 months",
    ],
    availability: [
      "In a week",
      "In a month",
      "In two months",
      "In three months",
      "In six months",
      "In a year",
    ],
  };
  const reset = () => {
    setFilters({
      selectedState: "",
      selectedCity: "",
      baths: "",
      cityTown: "",
      beds: "",
      price: "",
      sorts: "",
      type: "",
      rentPeriod: "",
      availability: "",
    });
  };
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(
          `https://secure.geonames.org/childrenJSON?geonameId=2300660&username=${geonamesUsername}`
        );
        const stateOptions = response.data.geonames.map((state) => ({
          value: state.geonameId,
          label: state.name,
        }));
        setStates(stateOptions);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, []);

  useEffect(() => {
    if (filters.selectedState) {
      const fetchCities = async () => {
        try {
          const response = await axios.get(
            `https://secure.geonames.org/childrenJSON?geonameId=${filters.selectedState}&username=${geonamesUsername}`
          );
          const cityOptions = response.data.geonames.map((city) => ({
            value: city.geonameId,
            label: city.name,
          }));
          setCities(cityOptions);
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      };

      fetchCities();
    } else {
      setCities([]);
    }
  }, [filters.selectedState]);
  const applyFilters = () => {
    setFilteredData(filters);
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    applyFilters();
  };

  const getFilterCount = () => {
    return Object.values(filters).filter((value) => value).length;
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setDropdownVisible(false);
      } else {
        setDropdownVisible(true);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="header1">
        <div className="flex button_filter">
          <button className="toggle-btnf" onClick={toggleDropdownVisibility}>
            <i
              className="fa fa-server"
              style={{ fontSize: "14px", marginRight: "10px" }}
            ></i>{" "}
            {"  "} Filters{" "}
            <span className="filter-count">{getFilterCount()}</span>
          </button>

          <button
            type="submit"
            className="btn btn--primary btn-filter2 "
            onClick={reset}
          >
            reset
          </button>
        </div>

        <div className={`${dropdownVisible ? "show dropdowns" : "d-none"}`}>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="selectedState"
              value={filters.selectedState}
              onChange={handleFilterChange}
            >
              <option value="">Region</option>
              {states.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="selectedCity"
              value={filters.selectedCity}
              onChange={handleFilterChange}
            >
              <option value="">District</option>
              {cities.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <input
              type="text"
              placeholder="City/Town"
             
              name="cityTown"
              className="filter-input"
              value={filters.cityTown}
              onChange={handleFilterChange}
            />
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="baths"
              value={filters.baths}
              onChange={handleFilterChange}
            >
              <option value="">Baths</option>
              {dropdownOptions.baths.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="dropdown">
            <select
              className="filter-btn "
              name="beds"
              value={filters.beds}
              onChange={handleFilterChange}
            >
              <option value="">Beds</option>
              {dropdownOptions.beds.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="dropdown">
            <select
              className="filter-btn "
              name="price"
              value={filters.price}
              onChange={handleFilterChange}
            >
              <option value="">Price</option>
              {dropdownOptions.price.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="dropdown">
            <select
              className="filter-btn "
              name="sorts"
              value={filters.sorts}
              onChange={handleFilterChange}
            >
              <option value="">Sort</option>
              {dropdownOptions.sorts.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="dropdown">
            <select
              className="filter-btn "
              name="type"
              value={filters.type}
              onChange={handleFilterChange}
            >
              <option value="">Type</option>
              {type?.map((option, i) => (
                <option key={i} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="rentPeriod"
              value={filters.rentPeriod}
              onChange={handleFilterChange}
            >
              <option value="">Rent Period</option>
              {dropdownOptions.rentPeriod.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="availability"
              value={filters.availability}
              onChange={handleFilterChange}
            >
              <option value="">Availability</option>
              {dropdownOptions.availability.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          {/* <button
            type="submit"
            className="btn btn--primary btn-filter1"
            onClick={applyFilters}
          >
            Filter
          </button> */}
          <button
            type="submit"
            className="btn btn--primary btn-filter1"
            onClick={reset}
          >
            reset
          </button>
        </div>
      </div>
      <Detail filters={filteredData} states={states} cities={cities} />
    </>
  );
};

export default HeaderHello;

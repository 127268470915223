/* eslint-disable array-callback-return */
import { toast } from "react-toastify";
import { axiosInstance } from "../../Config/AxiosInstance";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../Config/Baseurl/Baseurl";
const token = localStorage.getItem("Token");
export const Addproperty = (
  setSubmitting,
  values,
  selectedCity,
  selectedState,
  selectedAmenities,
  images,
  setSelectedAmenities,
  resetForm,
  setImages,
  setImageURLs
) => {
  setSubmitting(true);
  const Data = new FormData();
  Data.append("title", values.title);
  Data.append("description", values.description);
  Data.append("price", values.price);
  Data.append("sq_ft", values.sq_ft);
  Data.append("no_of_bedroom", values.bed);
  Data.append("no_of_bathroom", values.bath);
  Data.append("garage", values.garage);
  Data.append("type", values.type);
  Data.append("rent_period", values.rent_period);
  Data.append("availability", values.availability);
  Data.append("country", "Ghana");
  Data.append("district", selectedCity?.label);
  Data.append("region", selectedState?.label);
  Data.append("city", values?.city);
  Data.append("link", values?.link);
  // Data.append("region", selectedValues.region);
  Data.append("address", values.address);
  // Data.append("district", selectedValues.district);
  Data.append("amenities", JSON.stringify(selectedAmenities));
  Data.append("lease_term", values.lease_term);
  // Data.append("city", selectedValues.city);
  Data.append("suburb", values.suburb);

  if (images.length >= 5 && images.length <= 20) {
    images.forEach((image, i) => {
      Data.append(`images[${i}]`, image);
    });
  } else {
    toast.error("Number of images is not between 5 and 20");
  }

  axiosInstance
    .post("/listing", Data)
    .then((response) => {
      // console.log("Form submitted successfully!", response.data);
      toast.success("Property added successfully!");
      resetForm();
      setSelectedAmenities([]);
      setImages([]);
      setImageURLs([]);
    })
    .catch((error) => {
      console.error("Error submitting form:", error);
      // toast.error(error?.message);
    })
    .finally(() => {
      setSubmitting(false);
    });
};

export const fetchStates = async (setLoading, setStates, geonamesUsername) => {
  setLoading(true);
  try {
    const response = await axios.get(
      `https://secure.geonames.org/childrenJSON?geonameId=${2300660}&username=${geonamesUsername}`
    );
    const stateOptions = response.data.geonames.map((state) => ({
      value: state.geonameId,
      label: state.name,
    }));
    setStates(stateOptions);
  } catch (error) {
    console.error("Error fetching states:", error);
  } finally {
    setLoading(false);
  }
};
export const EditPropertys = async (
  setSubmitting,
  values,
  selectedCity,
  selectedState,
  selectedAmenities,
  images,
  state
) => {
  const Navigate = useNavigate();

  setSubmitting(true);
  const Data = new FormData();
  Data.append("title", values.title);
  Data.append("description", values.description);
  Data.append("price", values.price);
  Data.append("sq_ft", values.sq_ft);
  Data.append("no_of_bedroom", values.no_of_bedroom);
  Data.append("no_of_bathroom", values.no_of_bathroom);
  Data.append("garage", values.garage);
  Data.append("type", values.type);
  Data.append("rent_period", values.rent_period);
  Data.append("availability", values.availability);
  Data.append("country", "Ghana");
  Data.append("district", selectedCity.label);
  Data.append("region", selectedState.label);
  Data.append("city", values?.city);
  // Data.append("link", values?.link);
  // Data.append("region", selectedValues.region);
  Data.append("address", values.address);
  // Data.append("district", selectedValues.district);
  Data.append("amenities", JSON.stringify(selectedAmenities));
  Data.append("lease_term", values.lease_term);
  // Data.append("city", selectedValues.city);
  Data.append("suburb", values.suburb);

  images?.map((item, index) => {
    Data.append(`images[${index}][file]`, item);
  });

  try {
    await axios
      .post(`${baseUrl}/listing/${state?.id}`, Data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "X-Http-Method-Override": "PUT",
        },
      })
      .then((response) => {
        toast.success(response?.data?.message);
        if (response?.data?.status === true) {
          Navigate(-1);
        }
      });
  } catch (error) {
    toast.error(error?.message);
    console.error(error);
  }
};

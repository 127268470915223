import { axiosData } from "../Config/Axios";
// import { axiosInstance } from "../Config/AxiosInstance";

const amenities = [
  { name: "Ceiling Fan", fieldName: "amenities.ceilingFan" },
  { name: "Air Conditioner", fieldName: "amenities.airConditioner" },
  { name: "Parking Garage", fieldName: "amenities.parkingGarage" },
  { name: "Gym", fieldName: "amenities.gym" },
  { name: "Carpet Floor", fieldName: "amenities.carpetFloor" },
  { name: "Floor Tiles", fieldName: "amenities.floorTiles" },
  { name: "Smoking Allowed", fieldName: "amenities.smokingAllowed" },
  { name: "Pet Allowed", fieldName: "amenities.petAllow" },
  { name: "Party Allowed", fieldName: "amenities.partyAllow" },
  { name: "Children Allowed", fieldName: "amenities.childrenAllow" },
];

const allowed = [
  {
    smoking_allowed: false,
    pet_allowed: false,
    party_allowed: false,
    children_allowed: false,
  },
];
const types = [
  { value: "single-room", label: "Single Room" },
  { value: "single-room-self-contain", label: "Single Room (self-contain)" },
  { value: "one-bedroom-chamber-hall", label: "One bedroom (Chamber/Hall)" },
  {
    value: "one-bedroom-chamber-hall-sf",
    label: "One bedroom (Chamber/Hall SF)",
  },
  { value: "two-bedrooms", label: "Two bedrooms" },
  { value: "two-bedrooms-sf", label: "Two bedrooms (SF)" },
  { value: "three-bedrooms", label: "Three bedrooms" },
  { value: "three-bedrooms-sf", label: "Three bedrooms (SF)" },
  { value: "four-bedrooms", label: "Four bedrooms" },
  { value: "four-bedrooms-sf", label: "Four bedrooms (SF)" },
  { value: "apartment-complex", label: "Apartment Complex" },
  { value: "other", label: "Other" },
];
const GetData = () => {
  return axiosData.get("feature");
};
const Getlisting = () => {
  return axiosData.get("/listing-user");
};
const GetDataone = () => {
  return axiosData.get("type");
};
const GetAds = () => {
  return axiosData.get("advertisement");
};
const Gettitle = () => {
  return axiosData.get("title");
};
export const Rent_Period = [
  { name: "Daily" },
  { name: "Weekly" },
  { name: "1-to-6 months" },
  { name: "12 months" },
  { name: "12-24 months" },
  { name: "24-36 months" },
];
export const Availability = [
  {
    name: "In a week",
  },
  {
    name: "In a month",
  },
  {
    name: "In two months",
  },
  {
    name: "In three months",
  },
  {
    name: "In six months",
  },
  {
    name: "In a year",
  },
];

export { allowed, amenities, types, GetData, GetDataone, GetAds, Gettitle,Getlisting };

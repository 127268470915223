/* eslint-disable no-mixed-operators */
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "./Baseurl/Baseurl";

export const axiosData = axios.create({
  baseURL: baseUrl,
});

axiosData.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("Token");
    const user = JSON.parse(localStorage.getItem("user"));
    if ((token && user?.is_verified === "1") || user?.is_verified === 1) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosData.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const Data = error?.response?.data;
    const errors = Data.error;
    if (errors) {
      errors.map((e) => toast.error(e));
    } else {
      if (
        Data.message === "Unauthenticated." ||
        Data.message.includes("No query results for model")
      ) {
        //  toast.error("Plzz Login Your Account")
      } else {
        toast.error(Data.message);
      }
      if (Data.message.includes("No query results for model")) {
      } else {
        console.log(
          Data.message.includes("No query results for model"),
          Data.message
        );
      }
    }
    if (Data.message === "Unauthenticated.") {
      localStorage.clear();
      // window.location.pathname = "/login";

      localStorage.setItem("Unauthenticated", true);
    } else {
      localStorage.setItem("Unauthenticated", false);
    }
    return Promise.reject(error);
  }
);

import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import ScrollToTop from "../Scroltotop";
// import Banner from "../Ads/BannerHome";

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Header />
      {children}
      <ScrollToTop/>
      {/* <Banner /> */}
      <Footer />
    </div>
  );
};

export default Layout;

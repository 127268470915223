import React from "react";

function Banner() {
  return (
    <section
      id="page-title"
      className="page-title bg-overlay bg-overlay-dark2 bg-section"
      style={{ backgroundImage: 'url("assets/images/page-titles/fb.jpg")' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
            <div className="title title-1 text-center">
              <div className="title--content">
                <div className="title--heading">
                  <h1>RentisReady Store</h1>
                </div>
              </div>
              <div className="clearfix" />
            </div>
            {/* .title end */}
          </div>
          {/* .col-md-12 end */}
        </div>
        {/* .row end */}
      </div>
      {/* .container end */}
    </section>
  );
}

export default Banner;

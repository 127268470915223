import React, { useEffect, useRef, useState } from "react";

// import Detail from "../Component/Detail";
// import Layout from "../Component/Layout/Layout";
// import HeaderApartment from "../Header/Heade/Apartment";
import Layout from "../Layout/Layout";
// import HouseCom from "./HouseCom.";
import HeaderHouse from "../Header/HeaderHouse";
// import HeaderApartment from "../Header/HeaderApartment";
// import HeaderRoom from "../../Component/He";
import { axiosData } from "../../Config/Axios";
import BannerHome from "../Ads/BannerHome";

function House() {
  const [image, setImage] = useState([]);

  const GetData = () => {
    axiosData
      .get(`image-setting`)
      .then((res) => {
        if (res?.data?.status === true) {
          setImage(res?.data?.data);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let a = image?.[0]?.status_four ? 0 : 1;
  useEffect(() => {
    GetData();
    if (a < 1) {
      loginModal();
      a++;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [a]);
  const isLogin = useRef();
  const loginModal = () => {
    isLogin?.current?.click();
  };
  return (
    <Layout>
      <div className="top-res">
        <HeaderHouse />
      </div>
      {image?.[0]?.status_four === 1 && (
        <>
          <button
            ref={isLogin}
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#exampleModal"
            style={{ width: "0px", height: "0px" }}
          ></button>
          <BannerHome
            id={"exampleModal"}
            image={image?.[0]?.image_four}
            link={image?.[0]?.link_four}
          />
        </>
      )}
    </Layout>
  );
}

export default House;
